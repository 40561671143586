/*******************************
         Site Overrides
*******************************/

.ui.button {
  margin: 0;
  white-space: nowrap;
}

a.ui.button {
  display: inline-flex;
  align-items: center;
}

.ui.button:not(.mini, .tiny, .small, .variant--link) {
  min-height: 2.5rem;
}

.ui.button.small {
  padding: 0.4375rem 0.75rem;
  min-height: 2rem;
  font-size: @medium;
  line-height: 1rem;
}

.ui.outlined:not(.primary,.secondary,.positive,.negative),
.ui.button.disabled.outlined:not(.primary,.secondary,.positive,.negative),
.ui.button.outlined:disabled:not(.primary,.secondary,.positive,.negative) {
  /* !important necessary here for overriding Semantic UI */
  box-shadow: 0px 0px 0px 1px @background inset !important;
}

.ui.outlined {
  color: @background;
  background: var(--color-pure-white);
}

.ui.button.primary.outlined:not(:hover):not(:active):not(:focus) {
  box-shadow: 0px 0px 0px 1px @primaryColor inset !important;
  background: var(--color-pure-white);
  color: @primaryColor;
}

.ui.button.secondary.outlined:not(:hover):not(:active):not(:focus) {
  box-shadow: 0px 0px 0px 1px @secondaryColor inset !important;
  background: var(--color-pure-white);
  color: @secondaryColor;
}

.ui.basic.button.primary {
  border: @basicBorder;
  font-weight: bold;
  &:active {
    filter: brightness(95%);
  }
}

.ui.button.variant--link {
  &, &:active, &:hover, &:focus {
    background: unset;
    padding: unset;
    color: @secondaryColor;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    text-decoration: underline;
  }

  &:hover {
    color: darken(@secondaryColor, 6);
  }

  &:active {
    color: darken(@secondaryColor, 10);
  }

  &:hover {
    cursor: pointer;
  }

  &.primary {
    &, &:active, &:hover, &:focus {
      color: @primaryColor;
    }
    &:hover {
      color: darken(@primaryColor, 6);
    }

    &:active {
      color: darken(@primaryColor, 10);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.ui.loading.button.primary.outlined:not(:hover):not(:active):not(:focus):after,
.ui.loading.button.primary.outlined.disabled:after {
  border-color: @primaryColor transparent transparent;
}