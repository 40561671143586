/*******************************
        Site Overrides
*******************************/

.ui.message {
  border: 1px solid var(--color-grey);
}

.ui.message > .close.icon {
  color: var(--color-charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.message.error p {
  color: @red;
}