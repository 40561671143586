/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown .menu {
  border-width: 2px;
}

.ui.upward.selection.dropdown .menu {
  border-top-width: 2px !important;
}

.ui.selection.active.dropdown {
  border-width: 2px;
}

.ui.selection.active.dropdown .menu {
  min-width: calc(100% +  4px);
  margin: 0px -2px;
}

.ui.selection.dropdown.active {
  padding-left: calc(@selectionHorizontalPadding - 1px);
  padding-bottom: calc(@selectionVerticalPadding - 1px);
}

.ui.selection.dropdown .menu > .item {
  padding-left: calc(@selectionHorizontalPadding - 1px) !important;
}

.ui.search.selection.dropdown > input.search {
  padding-left: calc(@searchSelectionHorizontalPadding - 1px);
}

.ui.dropdown:not(.selection) .menu .selected.item:first-child,
.ui.dropdown:not(.selection) .menu > .item:first-child:hover {
  border-top-left-radius: @menuBorderRadius;
  border-top-right-radius: @menuBorderRadius;
}

.ui.dropdown:not(.selection) .menu .selected.item:last-child,
.ui.dropdown:not(.selection) .menu > .item:last-child:hover {
  border-bottom-left-radius: @menuBorderRadius;
  border-bottom-right-radius: @menuBorderRadius;
}

.ui.form .field.error .ui.selection.dropdown .default.text,
.ui.form .field .ui.selection.dropdown .default.text {
  color: var(--color-charcoal);
}