/*******************************
         Site Overrides
*******************************/

.ui.label {
  min-width: 2rem;
  line-height: 1rem;
}

.ui.circular.label {
  line-height: 1rem;
}