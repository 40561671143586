/*******************************
         Site Overrides
*******************************/
.ui.form .field textarea {
  resize: none;
}

.ui.form .field textarea,
.ui.form input:not([type]), 
.ui.form input[type="date"], 
.ui.form input[type="datetime-local"], 
.ui.form input[type="email"], 
.ui.form input[type="number"], 
.ui.form input[type="password"], 
.ui.form input[type="search"], 
.ui.form input[type="tel"], 
.ui.form input[type="time"], 
.ui.form input[type="text"], 
.ui.form input[type="file"], 
.ui.form input[type="url"] {
  font-family: var(--font-family);
  transition: color 0.2s ease, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.ui.form .field textarea:focus,
.ui.form input:not([type]):focus, 
.ui.form input[type="date"]:focus, 
.ui.form input[type="datetime-local"]:focus, 
.ui.form input[type="email"]:focus, 
.ui.form input[type="number"]:focus, 
.ui.form input[type="password"]:focus, 
.ui.form input[type="search"]:focus, 
.ui.form input[type="tel"]:focus, 
.ui.form input[type="time"]:focus, 
.ui.form input[type="text"]:focus, 
.ui.form input[type="file"]:focus, 
.ui.form input[type="url"]:focus {
  box-shadow: inset 0 0 0 1px @focusedFormBorderColor;
}

.ui.form .field.error textarea:focus, 
.ui.form .field.error select:focus, 
.ui.form .field.error input:not([type]):focus, 
.ui.form .field.error input[type="date"]:focus, 
.ui.form .field.error input[type="datetime-local"]:focus, 
.ui.form .field.error input[type="email"]:focus, 
.ui.form .field.error input[type="number"]:focus, 
.ui.form .field.error input[type="password"]:focus, 
.ui.form .field.error input[type="search"]:focus, 
.ui.form .field.error input[type="tel"]:focus, 
.ui.form .field.error input[type="time"]:focus, 
.ui.form .field.error input[type="text"]:focus, 
.ui.form .field.error input[type="file"]:focus, 
.ui.form .field.error input[type="url"]:focus {
  box-shadow: inset 0 0 0 1px @negativeBorderColor;
}

.ui.form .fields.error .field .ui.dropdown .item.active, 
.ui.form .field.error .ui.dropdown .item.active,
.ui.form .field.error .ui.dropdown .item.active .text {
  background: @primaryColor;
  color: var(--color-pure-white);
}

.field > label {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}