*,
*::before,
*::after {
    box-sizing: border-box;
}

:root {
    --font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

    // Sizings/Spacings
    --sidebar-width: 16rem;
    --small: 1rem;
    --x-small: 0.5rem;
    --2x-small: 0.25rem;
    --3x-small: 0.125rem;
    --medium: 1.5rem;
    --large: 2rem;
    --x-large: 2.5rem;
    --2x-large: 4rem;

    // Raw colors
    --color-red: #CC0000;
    --color-green: #59B755;
    --color-charcoal: #5A5A5A;
    --color-off-white: #F6F6F6;
    --color-pure-white: white;
    --color-gray: #E4E4E4;
    --color-grey: var(--color-gray);
    --color-orange: #CC5500;
    --color-turquoise: #0099A3;
    --color-light-orange: #F5DDCC;
    --color-midnight-blue: #011B2A;
    --color-pure-black: black;
    --color-blue: #03598C;
    --color-light-blue: #CDDEE8;

    // Semantic colors
    --bg-primary-dark: var(--color-midnight-blue);
    --color-primary: var(--color-orange);
    --color-primary-light: var(--color-light-orange);
    --color-primary-alt: var(--color-turquoise);
    --color-secondary: var(--color-blue);
    --color-secondary-light: var(--color-light-blue);
    --color-success: var(--color-green);
}

html {
    font-size: 16px;
}

body {
    background: var(--color-off-white);
    font-size: 16px;
    margin: 0;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a[href],
.ui.breadcrumb a {
    color: #03598C;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app-content {
    min-height: 100vh;
    background: var(--color-off-white);
}

footer {
    background-color: hsl(0, 0%, 89.4%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.004375rem;
    color: var(--color-charcoal);
}

.field.error .ui.selection.active.dropdown .menu {
    border-color: var(--color-red);
}

.ui.form .field.error .ui.dropdown .menu .item:hover {
    &, & > .text {
        color: var(--color-pure-white);
        background: var(--color-primary);
    }
}

div * {
  scrollbar-width: 0.25rem; /* Firefox */
  -ms-overflow-style: auto; /* IE 10+ */
  ::-webkit-scrollbar {
    width: 0.25rem !important;
  }
}

.CollapsibleContent {
  overflow: hidden;
}
.CollapsibleContent[data-state='open'] {
  animation: slideDown 175ms ease-out;
}
.CollapsibleContent[data-state='closed'] {
  animation: slideUp 175ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
    opacity: 1;
  }
  to {
    height: 0;
    opacity: 0;
  }
}